import React, { useState } from 'react';
import { motion } from 'framer-motion';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// Components
import { BlockWrapper } from '../blockWrapper';

import { SvgIcon } from '../../media/svgIcon';

const Card = ({ title, text, target, icon, backgroundColor, hoverColor }) => {
  const [isHovered, setHovered] = useState(false);

  const bgColor = (backgroundColor && backgroundColor.hex) || '#FEF1E9';
  const hColor = (hoverColor && hoverColor.hex) || '#F37021';

  return (
    <motion.div
      className="rounded-md box-border relative pb-square lg:pb-portrait"
      animate={{
        backgroundColor: isHovered ? hColor : bgColor,
        color: isHovered ? '#fff' : '#414242',
        boxShadow: isHovered
          ? '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)'
          : 'none',
      }}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <AnchorLink
        href={`#${target}`}
        className="absolute top-0 left-0 p-4 grid w-full h-full cursor-pointer lg:p-6"
      >
        <div className="flex flex-col justify-between lg:flex-row-reverse lg:items-center lg:self-start h-full lg:h-auto">
          <div className="w-10 lg:w-8 ">
            <SvgIcon icon={icon} />
          </div>
          <h5 className="font-display font-black lg:text-xl">{title}</h5>
        </div>
        <div className="text-lg hidden lg:block self-end">
          <motion.p
            animate={{
              y: isHovered ? 0 : 20,
            }}
            transition={{ ease: 'easeOut', duration: 0.3 }}
          >
            {text}
          </motion.p>
          <motion.span
            className="font-semibold mt-4 block"
            animate={{
              opacity: isHovered ? 1 : 0,
              y: isHovered ? 0 : 20,
            }}
            transition={{
              ease: 'easeOut',
              duration: 0.3,
              delay: isHovered ? 0.1 : 0,
            }}
          >
            Read More
          </motion.span>
        </div>
      </AnchorLink>
    </motion.div>
  );
};

const CardGrid = ({ items, sectionSlug, padding, blockDisplay, backgroundColor }) => {
  return (
    <BlockWrapper
      className="grid grid-cols-14"
      id={sectionSlug && sectionSlug.current}
      style={{ backgroundColor: backgroundColor && backgroundColor.hex }}
      padding={padding}
      blockDisplay={blockDisplay}
    >
      {/* Inner Grid */}
      <div className="col-start-2 col-end-14 md:col-start-3 md:col-end-13 grid grid-cols-2 gap-3 md:grid-cols-4 lg:gap-5">
        {items.map((item) => (
          <Card {...item} />
        ))}
      </div>
    </BlockWrapper>
  );
};

export default CardGrid;
