import React from "react"

import { useEmblaCarousel } from "embla-carousel/react"

const TrapperStat = ({ title, start, end, showLive, preffix, suffix }) => {
  return (
    <div className="text-center w-full px-4" style={{ flex: "0 0 auto" }}>
      <div className="bg-white rounded-md p-4 shadow-xl">
        <h3 className="text-brand font-display text-5xl">
          {preffix}
          {end}
          {suffix}
        </h3>
        <h4 className="text-l font-semibold">{title}</h4>
        {showLive && (
          <span className="bg-tintorange text-brand font-bold rounded-full py-0 px-6 mt-4 inline-block text-sm relative">
            <span className="animate-pulse">Live</span>
          </span>
        )}
      </div>
    </div>
  )
}

export const TrapperCarousel = ({ items }) => {
  const [emblaRef] = useEmblaCarousel({
    loop: false,
    align: "start",
    containScroll: true,
    startIndex: 1,
  })

  return (
    <div ref={emblaRef}>
      <div className="flex">
        {items.map(item => (
          <TrapperStat {...item} />
        ))}
      </div>
    </div>
  )
}
