import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { useNavigate, useLocation } from '@reach/router';

import moduleStyles from './dripForm.module.css';

import { PortableTextBlock } from '../sanity/portableTextBlock';
import { StandardInput, NextButton, QuizForm, Spinner } from '../forms';

const initialFormState = {
  isSignedUp: false,
  subscriberEmail: null,
  quizActive: true,
};

export const DripSubscribeForm = ({
  introText,
  backgroundColor,
  successMessage,
  tag,
  quiz,
  onClose,
}) => {
  const [dripForm, setDripForm] = useState(initialFormState);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // Handle Submit
  const handleDripSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    await fetch('/.netlify/functions/drip-sign-up', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: dripForm.subscriberEmail, tag }),
    }).then((res) => {
      setDripForm((prevValues) => ({
        ...prevValues,
        isSignedUp: true,
      }));
    });
  };

  // Start Quiz Look
  const startQuiz = () => {
    setDripForm((prevValues) => ({
      ...prevValues,
      quizActive: true,
    }));
  };

  useEffect(() => {
    navigate(`${location.pathname}?form_step=signup`);
  }, []);

  return (
    <section
      className={`${moduleStyles.container} text-black`}
      style={{ background: backgroundColor && backgroundColor.hex }}
    >
      <AnimatePresence exitBeforeEnter>
        {!dripForm.isSignedUp ? (
          <motion.form
            className="grid grid-cols-14 h-screen grid-rows-3 overflow-y-hidden"
            onSubmit={(e) => handleDripSubmit(e)}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            key="subForm"
          >
            <div className="col-start-2 col-end-14 md:col-start-3 md:col-end-13 lg:col-start-5 lg:col-end-11 row-start-2 row-end-2 text-center">
              <PortableTextBlock text={introText} />
              <div className="grid grid-cols-2">
                <StandardInput
                  type="email"
                  placeholder="Enter your email address"
                  className="col-start-1 col-end-3  row-start-1 row-end-1"
                  required={true}
                  handleChange={(event) => {
                    event.persist();
                    setDripForm((prevValues) => ({
                      ...prevValues,
                      subscriberEmail: event.target.value,
                    }));
                  }}
                />
                {loading ? (
                  <div className="col-start-2 col-end-2 row-start-1 row-end-1 justify-self-end self-center text-brand">
                    <Spinner />
                  </div>
                ) : (
                  <NextButton
                    className="col-start-2 col-end-2 row-start-1 row-end-1 justify-self-end self-center"
                    type="submit"
                  />
                )}
              </div>
            </div>
          </motion.form>
        ) : !dripForm.quizActive ? (
          <motion.div
            className="grid grid-cols-14 h-screen"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            key="successText"
          >
            <div className="col-start-2 col-end-14 md:col-start-3 md:col-end-13 lg:col-start-5 lg:col-end-11 row-start-2 row-end-2 text-center">
              <PortableTextBlock text={successMessage} />
              {quiz.attachQuiz && (
                <button
                  className="bg-brand rounded-full font-semibold text-lg py-2 px-12 shadow-lg text-white"
                  onClick={startQuiz}
                >
                  {quiz.linkText}
                </button>
              )}
            </div>
          </motion.div>
        ) : (
          <QuizForm {...quiz} {...dripForm} onClose={onClose} />
        )}
      </AnimatePresence>
    </section>
  );
};
