import React from 'react';
import { graphql } from 'gatsby';

// Hooks & Querys
import { QueryFragments } from '../GraphQl/queryFragments'; //eslint-disable-line

import Layout from '../components/layout';
import SEO from '../components/seo';

import BlockZone from '~blockZone';
import PageFooter from '../components/nav/pageFooter';

const LandingPage = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data && data.page;

  const { metaDescription, metaTitle, metaKeywords, robots } = page.meta || {};
  const { pageBlocks, footer, hideFooter } = page || {};
  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        metaRobots={robots}
      />
      {pageBlocks && <BlockZone {...pageBlocks} />}
      {footer && !hideFooter && <PageFooter {...footer} />}
    </Layout>
  );
};

export default LandingPage;

export const pageQuery = graphql`
  query landingPageQuery($slug: String!) {
    page: sanityLandingPage(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZoneFields
      }
      hideFooter
      footer {
        ...PageFooterFields
      }
    }
  }
`;
