import React from 'react';

import moduleStyles from './svgIcon.module.css';

export const SvgIcon = ({ icon, className, fillCurrent = true }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: icon }}
      className={`${moduleStyles.container} ${fillCurrent && 'fill-current'} ${
        className && className
      }`}
    />
  );
};
