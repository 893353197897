import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Components
import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: '0px' },
};

const FaqRow = ({ title, _rawText, _key, expanded, setExpanded }) => {
  const isOpen = _key === expanded;
  return (
    <div className="py-2 prose-xl max-w-none lg:py-3">
      <h6
        className="font-display py-2 cursor-pointer flex justify-between items-center lg:py3"
        onClick={() => setExpanded(isOpen ? false : _key)}
      >
        {title}
        {isOpen ? (
          <span className="font-black text-2xl w-10 self-start text-right">&ndash;</span>
        ) : (
          <span className="font-black text-2xl w-10 self-start text-right">+</span>
        )}
      </h6>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key={_key}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={variants}
            transition={{ duration: 0.2, ease: 'easeIn' }}
            className="overflow-hidden"
          >
            <PortableTextBlock text={_rawText} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const Faqs = ({
  heading,
  sectionSlug,
  helpLink,
  padding,
  blockDisplay,
  items,
  backgroundColor,
}) => {
  const [expanded, setExpanded] = useState(0);

  return (
    <BlockWrapper
      className="grid grid-cols-14 text-goodFooter "
      id={sectionSlug && sectionSlug.current}
      style={{ backgroundColor: backgroundColor && backgroundColor.hex }}
      blockDisplay={blockDisplay}
      padding={padding}
    >
      <div className="prose-xl lg:prose-2xl text-center col-start-2 col-end-14">
        <h3 className="font-display">{heading}</h3>
      </div>
      <div className="col-start-2 col-end-14 md:col-start-4 md:col-end-12 my-20 divide-y border-t border-b border-goodFooter divide-goodFooter">
        {items.map((item) => (
          <FaqRow {...item} expanded={expanded} setExpanded={setExpanded} />
        ))}
      </div>
      <div className="col-start-2 col-end-14 text-center prose-xl">
        {helpLink && (
          <a
            href={helpLink.link}
            target="_blank"
            className="text-brand underline font-semibold"
            rel="noreferrer"
          >
            {helpLink.linkText}
          </a>
        )}
      </div>
    </BlockWrapper>
  );
};

export default Faqs;
