import React from "react"

import RightArrow from "../../svg/rightArrow.svg"

export const NextButton = ({ className, ...props }) => {
  return (
    <button
      {...props}
      className={`bg-brand text-white w-8 h-8 rounded-full p-2 ${className}`}
    >
      <RightArrow />
    </button>
  )
}
export const NextDivButton = ({ className, ...props }) => {
  return (
    <div
      {...props}
      className={`bg-brand text-white w-8 h-8 rounded-full p-2 ${className}`}
    >
      <RightArrow />
    </div>
  )
}
