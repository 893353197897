import React from 'react';

// hooks
import { useMediaQuery } from '@react-hook/media-query';
import { useMeasure } from '../../../hooks';

// Components
import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity';
import { SvgIcon } from '../../media/svgIcon';

import CardCarousel from '../../carousels/cardCarousel';

const FeatureSection = ({
  _rawText,
  text,
  sectionSlug,
  backgroundColor,
  textColor,
  carouselImages,
  icon,
  layout,
  blockDisplay,
}) => {
  const matches = useMediaQuery('only screen and (min-width: 1024px) and (min-height: 780px)');

  const [{ ref }, bounds] = useMeasure();
  const blockText = _rawText || text;
  return (
    <BlockWrapper
      className="bg-tintorange text-goodFooter grid grid-cols-14 gap-y-10 py-20 lg:py-48 lg:gap-y-0
    "
      id={sectionSlug && sectionSlug.current}
      style={{
        backgroundColor: backgroundColor && backgroundColor.hex,
        color: textColor && textColor.hex,
      }}
      blockDisplay={blockDisplay}
    >
      {/* Text */}
      <div
        className={`col-start-2 col-end-14 md:row-start-1 ${
          layout === 'right' ? 'md:col-end-7 lg:col-start-3' : 'md:col-start-9 lg:col-end-13'
        }`}
      >
        <div
          className="lg:sticky"
          ref={ref}
          style={{ top: matches && `calc(${bounds.width / 2}px - 6rem)` }}
        >
          <div className="w-10 mb-6 md:mb-3 lg:w-16">
            <SvgIcon icon={icon} />
          </div>
          <PortableTextBlock text={blockText} className="prose-xl xl:prose-2xl max-w-none" />
        </div>
      </div>
      {/* Image Carousel */}
      <div
        className={`col-start-2 col-end-14 md:row-start-1 ${
          layout === 'right'
            ? 'md:col-start-8 lg:col-end-13'
            : 'md:col-start-2 md:col-end-8 lg:col-start-3'
        }`}
      >
        <CardCarousel items={carouselImages} />
      </div>
    </BlockWrapper>
  );
};

export default FeatureSection;
