import React from 'react';

import { useTailPadTop, useTailPadBot } from '../../hooks';
import { useMediaQuery } from '@react-hook/media-query';

export const BlockWrapper = ({ className, padding, blockDisplay, children, ...rest }) => {
  const paddingTop = useTailPadTop((padding && padding.top) || '0');
  const paddingBot = useTailPadBot((padding && padding.bottom) || '0');
  const matches = useMediaQuery('only screen and (min-width: 768px)');
  const { hideMobile, hideDesktop } = blockDisplay || {};

  const showBlock = matches ? !hideDesktop : !hideMobile;

  return (
    showBlock && (
      <section className={`${paddingTop} ${paddingBot} ${className}`} {...rest}>
        {children}
      </section>
    )
  );
};
