import React, { useState } from 'react';

// hooks
import { useMediaQuery } from '@react-hook/media-query';
import { useOfferLayout } from '../../../hooks';

import CloseIcon from '../../../svg/cross.svg';

import { StandardModal } from '../../modals/standardModal';
import { DripSubscribeForm } from '../../forms';

import { PortableTextBlock } from '../../sanity/portableTextBlock';

const PersistentModalForm = ({ badgeText, _rawText, text, layout, form, blockDisplay }) => {
  const matches = useMediaQuery('only screen and (min-width: 768px)');

  const layoutClass = useOfferLayout(layout || 'center');

  const [isOpen, setOpen] = useState(false);

  const { hideMobile, hideDesktop } = blockDisplay || {};
  const showBlock = matches ? !hideDesktop : !hideMobile;

  const onClose = () => {
    setOpen(false);
  };

  const blockText = _rawText || text;

  return (
    showBlock && (
      <>
        <div
          className={`fixed bottom-0 px-gutter pb-4 flex justify-center z-50 w-full lg:pb-6 cursor-pointer ${layoutClass}`}
          onClick={() => setOpen(true)}
        >
          <div
            className="bg-white rounded-full shadow-xl p-1 flex items-center border-gray-200 border text-xs md:text-sm"
            target="_blank"
            rel="noreferrer"
          >
            {badgeText && badgeText !== '' && (
              <span className="bg-brand rounded-full text-white font-black py-px px-3">
                {badgeText}
              </span>
            )}
            <div className="px-3 text-brand font-black">
              <PortableTextBlock text={blockText} />
            </div>
          </div>
        </div>
        {form && (
          <StandardModal isOpen={isOpen}>
            <div onClick={onClose} className="absolute right-0 top-0 p-6 cursor-pointer">
              <div className="w-6">
                <CloseIcon />
              </div>
            </div>
            <div className="prose-xl lg:prose-2xl max-w-none">
              <DripSubscribeForm {...form} onClose={onClose} />
            </div>
          </StandardModal>
        )}
      </>
    )
  );
};

export default PersistentModalForm;
