import React, { useState } from "react"

import CloseIcon from "../../svg/cross.svg"

import { DripSubscribeForm } from "../forms"

import { StandardModal } from "../modals/standardModal"

export const ModalForm = ({ linkText, form }) => {
  const [isOpen, setOpen] = useState(false)

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <button
        className="bg-brand rounded-full font-semibold text-lg py-1 px-12 shadow-lg text-white"
        onClick={() => setOpen(true)}
      >
        {linkText}
      </button>
      <StandardModal isOpen={isOpen}>
        <div
          onClick={onClose}
          className="absolute right-0 top-0 p-6 cursor-pointer"
        >
          <div className="w-6">
            <CloseIcon />
          </div>
        </div>
        <div>
          <DripSubscribeForm {...form} onClose={onClose} />
        </div>
      </StandardModal>
    </>
  )
}
