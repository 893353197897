import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Hooks
import { useScrollFreeze } from '../../hooks';

const ModalInner = ({ children, ...props }) => {
  useScrollFreeze();
  return (
    <motion.div
      {...props}
      className="w-screen h-screen top-0 left-0 z-50 bg-tintorange fixed overflow-y-auto"
    >
      {children}
    </motion.div>
  );
};

export const StandardModal = ({ isOpen, backgroundColor, children }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <ModalInner
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'spring', damping: 10 }}
        >
          {children}
        </ModalInner>
      )}
    </AnimatePresence>
  );
};
