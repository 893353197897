import React from 'react';
import Image from 'gatsby-plugin-sanity-image';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const ArtImage = ({ imageSmall, imageLarge, ...props }) => {
  const breakpoints = useBreakpoint();

  return breakpoints.sm ? (
    <Image {...imageSmall} {...props} />
  ) : (
    <Image {...imageLarge} {...props} />
  );
};

export default ArtImage;
