import React from "react"

import moduleStyles from "./formInputs.module.css"

import { SvgIcon } from "../media/svgIcon"

export const StandardInput = ({
  type = "text",
  className,
  handleChange,
  ...props
}) => {
  return (
    <input
      {...props}
      type={type}
      className={`bg-transparent border-black border-b-4 w-full outline-none py-2 ${className} placeholder-current font-semibold appearance-none rounded-none`}
      onChange={handleChange}
    />
  )
}

const IconRadio = ({
  fieldName,
  icon,
  title,
  required,
  handleChange,
  nextStep,
}) => {
  return (
    <label className={`mx-4 ${moduleStyles.iconRadio}`}>
      <input
        type="radio"
        name={fieldName}
        value={title}
        required={required}
        className="hidden"
        onChange={handleChange}
      />
      <span className="w-16 h-16 md:w-20 md:h-20 lg:w-32 lg:h-32  flex justify-center items-center bg-white rounded-full">
        <SvgIcon icon={icon} className="w-8 md:w-12 lg:w-16" />
      </span>
      {title}
    </label>
  )
}

export const IconRadios = ({ fieldName, required, options, handleChange }) => {
  return (
    <div className="flex justify-center">
      {options.map(item => (
        <IconRadio
          {...item}
          fieldName={fieldName}
          required={required}
          handleChange={handleChange}
        />
      ))}
    </div>
  )
}
