import React from 'react';

import { PortableTextBlock } from '../sanity/portableTextBlock';

import LogoIcon from '../../svg/logo.svg';

import Amex from '../../svg/Amex.svg';
import ApplePay from '../../svg/ApplePay.svg';
import MasterCard from '../../svg/Mastercard.svg';
import PayPal from '../../svg/PayPal.svg';
import ShopPay from '../../svg/ShopPay.svg';
import Visa from '../../svg/Visa.svg';
import AfterPay from '../../svg/Afterpay.svg';
import LayBuy from '../../svg/Laybuy.svg';

const PaymentProviders = () => {
  return (
    <div className="col-start-2 col-end-14 flex mt-6 flex-wrap justify-items-start">
      <div className="mr-1 my-1">
        <Amex />
      </div>
      <div className="mr-1 my-1">
        <ApplePay />
      </div>
      <div className="mr-1  my-1">
        <MasterCard />
      </div>
      <div className="mr-1 my-1">
        <PayPal />
      </div>
      <div className="mr-1 my-1">
        <ShopPay />
      </div>
      <div className="mr-1 my-1">
        <Visa />
      </div>
      <div className="mr-1 my-1">
        <AfterPay />
      </div>
      <div className="mr-1 my-1">
        <LayBuy />
      </div>
    </div>
  );
};

const PageFooter = ({ contactDetails, quickLinks }) => {
  return (
    <div className="bg-goodFooter text-white grid grid-cols-14 py-10 lg:py-32 ">
      {/* Contact Details */}
      <div className="col-start-2 col-end-14 md:row-start-1 md:col-end-7">
        <div className="w-40">
          <LogoIcon />
        </div>
        <div className="prose-lg mt-4">
          <PortableTextBlock text={contactDetails} />
        </div>
      </div>
      {/* Border */}
      <div className="hidden lg:flex bg-white w-px col-start-7 row-start-1 justify-self-center" />
      {/* Quicklinks */}
      <div className="col-start-2 col-end-14 mt-6 md:col-start-8 md:row-start-1 md:mt-0 lg:col-start-9">
        <h4 className="mb-3 font-black text-xl">Quick Links</h4>
        <nav className="grid row-gap-2 md:grid-cols-2">
          {quickLinks.map((item) => (
            <a href={item.link} target="_blank" rel="noreferrer">
              {item.linkText}
            </a>
          ))}
        </nav>
      </div>
      <PaymentProviders />
    </div>
  );
};

export default PageFooter;
