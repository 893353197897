import React, { useState, useEffect } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import { Link } from 'react-scroll';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { BlockWrapper } from '../blockWrapper';

// Hooks
import { useMediaQuery } from '@react-hook/media-query';

const NavLink = ({ linkText, sectionID, index, setActiveIndex, activeIndex }) => {
  const isActive = index === activeIndex;

  return (
    <Link
      spy={true}
      smooth={true}
      to={sectionID}
      offset={-40}
      className={`pl-4 font-display text-sm md:px-4 relative transition-colors cursor-pointer ${
        isActive ? 'text-brand' : 'text-goodFooter'
      }`}
      style={{ flex: '0 0 auto' }}
      onSetActive={() => setActiveIndex(index)}
    >
      <div className="w-full relative">
        {isActive && (
          <motion.span className="w-full h-1 block bg-brand absolute -top-1" layoutId="line" />
        )}
      </div>
      {linkText}
    </Link>
  );
};

const PageNav = ({ blockDisplay, items, position }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const matches = useMediaQuery('only screen and (min-width: 768px)');

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: matches ? 'start' : 'center',
    containScroll: true,
    startIndex: 0,
  });

  useEffect(() => {
    if (emblaApi) {
      // Embla API is ready
      emblaApi.scrollTo(activeIndex);
    }
  }, [activeIndex, emblaApi]);

  return (
    <BlockWrapper
      blockDisplay={blockDisplay}
      className={`px-gutter sticky top-0 bg-white z-20 overflow-hidden`}
    >
      <AnimateSharedLayout>
        <div ref={emblaRef}>
          <div className="flex py-4 md:py-6 justify-start md:justify-center -ml-4">
            {items.map((item, index) => (
              <NavLink
                {...item}
                index={index}
                setActiveIndex={setActiveIndex}
                activeIndex={activeIndex}
              />
            ))}
          </div>
        </div>
      </AnimateSharedLayout>
    </BlockWrapper>
  );
};

export default PageNav;
