import React, { useState } from 'react';
import Image from 'gatsby-plugin-sanity-image';

import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';

import { PortableTextBlock } from '../sanity/portableTextBlock';

// Card
const CarouselCard = ({ _rawCaption, setPageIndex, pageIndex, key, ...props }) => {
  return (
    <motion.div
      className="absolute top-0 left-0 h-full w-full grid grid-rows-2 grid-cols-1"
      key={pageIndex}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      drag="x"
      dragMomentum={false}
      dragElastic={false}
      dragConstraints={{ left: 0, right: 0 }}
      onDragEnd={(e, { offset, velocity }) => {
        if (offset.x > 100) {
          setPageIndex(pageIndex - 1);
        } else if (offset.x < -100) {
          setPageIndex(pageIndex + 1);
        }
      }}
    >
      <div className="row-start-1 row-end-3 col-start-1 relative">
        <Image {...props} className="object-cover h-full w-full absolute" />
      </div>
      {_rawCaption && (
        <motion.div
          className="absolute bottom-0 left-0 p-2 lg:p-6"
          initial={{ y: 10 }}
          animate={{ y: 0 }}
          exit={{ y: 10 }}
        >
          <div className="bg-black text-white text-sm rounded-md py-1 px-3 leading-snug max-w-xs">
            <PortableTextBlock text={_rawCaption} />
          </div>
        </motion.div>
      )}
    </motion.div>
  );
};

const CardCarousel = ({ items }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const wrappedIndex = wrap(0, items.length, pageIndex);

  return (
    <div>
      {/* // Carousel */}
      <div className="bg-black rounded-xl shadow-2xl pb-gallerycard overflow-hidden relative h-0">
        <button
          className="hidden md:block absolute h-full w-32 top-0 left-0 z-20 cursor-wresize opacity-0"
          onClick={() => setPageIndex(pageIndex - 1)}
        >
          Prev
        </button>
        {items.length && (
          <AnimatePresence exitBeforeEnter>
            <CarouselCard
              {...items[wrappedIndex]}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
            />
          </AnimatePresence>
        )}
        <button
          className="hidden md:block absolute h-full w-32 top-0 right-0 z-20 opacity-0 cursor-eresize"
          onClick={() => setPageIndex(pageIndex - 1)}
        >
          Next
        </button>
      </div>
      {items.length > 1 && (
        <div className="flex justify-center mt-6">
          {items.map((item, index) => (
            <button
              onClick={() => setPageIndex(index)}
              className={`text-hidden bg-current rounded-full h-2 w-2 mx-1 focus:outline-none ${
                wrappedIndex === index ? 'opacity-100' : 'opacity-25'
              }`}
            >
              {index}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CardCarousel;
