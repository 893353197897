import React from 'react';

// Sanity Config
import BlockContent from '@sanity/block-content-to-react';
import Image from 'gatsby-plugin-sanity-image';

// Block Components
import {
  VideoModule,
  BlockImage,
  ButtonAnchor,
  ButtonLink,
  CheckList,
  ModalForm,
} from '../portableTextBlocks';

const BlockHeading = (props) => {
  switch (props.style) {
    case 'h1':
      return <h1 className="font-display font-black leading-loose">{props.children}</h1>;
    case 'h2':
      return <h2 className="font-display font-black">{props.children}</h2>;
    case 'h3':
      return <h3 className="font-display font-black">{props.children}</h3>;
    case 'h4':
      return <h4 className="font-display font-black">{props.children}</h4>;
    case 'h5':
      return <h5 className="font-display font-black">{props.children}</h5>;
    case 'h6':
      return <h6 className="font-display font-black">{props.children}</h6>;
    default:
      return null;
  }
};

const textColor = (props) => {
  const { hex } = props.mark;
  return <span style={{ color: hex }}>{props.children}</span>;
};

const BlockRenderer = (props) => {
  const { style = 'normal' } = props.node;

  if (/^h\d/.test(style)) {
    return <BlockHeading style={style}>{props.children}</BlockHeading>;
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

const link = (props) => {
  return (
    <a href={props.mark.href} target="_blank" rel="noreferrer" className="underline">
      {props.children}
    </a>
  );
};

export const Serializers = {
  types: {
    block: BlockRenderer,
    videoModule: ({ node }) => <VideoModule {...node} />,
    blockImage: ({ node }) => <Image {...node} />,
    buttonAnchor: ({ node }) => <ButtonAnchor {...node} />,
    buttonLink: ({ node }) => <ButtonLink {...node} />,
    checkList: ({ node }) => <CheckList {...node} />,
    modalForm: ({ node }) => <ModalForm {...node} />,
  },
  marks: { textColor, link },
};
