import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { useNavigate, useLocation } from '@reach/router';

// SVG
import ArrowRight from '../../svg/rightArrow.svg';
import ArrowLeft from '../../svg/leftArrow.svg';

import { StandardInput, IconRadios, Spinner } from '../forms';

import { PortableTextBlock } from '../sanity/portableTextBlock';
import { NextDivButton, NextButton } from '../forms';

const QuizStep = ({
  nextStep,
  handleChange,
  step: { stepText, field },
  isFinal,
  canContinue,
  snakedName,
  handleKeyDown,
  loading,
  activeStep,
  prevStep,
  nextText,
}) => {
  const stepField = field[0] || {};

  return (
    <motion.div
      className="text-center col-start-2 col-end-14 md:col-start-3 md:col-end-13 lg:col-start-5 lg:col-end-11 self-center"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
    >
      <PortableTextBlock text={stepText} />
      {stepField._type === 'formElement' ? (
        <div className="grid grid-cols-2">
          <StandardInput
            type={stepField.inputType}
            placeholder={stepField.label}
            required={stepField.required}
            className="col-start-1 col-end-3 row-start-1 row-end-1"
            handleChange={handleChange}
            name={snakedName}
            onKeyDown={handleKeyDown}
          />
          {isFinal ? (
            <>
              {!loading ? (
                <NextButton
                  className="col-start-2 col-end-2 row-start-1 row-end-1 justify-self-end self-center"
                  type="submit"
                  disabled={!canContinue}
                />
              ) : (
                <div className="col-start-2 col-end-2 row-start-1 row-end-1 justify-self-end self-center text-brand">
                  <Spinner />
                </div>
              )}
            </>
          ) : (
            canContinue && (
              <NextDivButton
                className="col-start-2 col-end-2 row-start-1 row-end-1 justify-self-end self-center"
                onClick={nextStep}
              />
            )
          )}
        </div>
      ) : (
        stepField._type === 'iconRadios' && (
          <div>
            <IconRadios
              {...stepField}
              nextStep={nextStep}
              handleChange={handleChange}
              fieldName={snakedName}
            />
          </div>
        )
      )}
      <nav className="justify-between pt-6 text-lg font-semibold flex md:hidden">
        {/* Back */}
        {activeStep > 0 ? (
          <div className="flex items-center cursor-pointer" onClick={prevStep}>
            <div className="w-4 mr-2">
              <ArrowLeft />
            </div>
            Back
          </div>
        ) : (
          <div className="h-7" />
        )}
        {/* Next */}
        {canContinue && !isFinal && (
          <div className="flex items-center cursor-pointer" onClick={nextStep}>
            {nextText}
            <div className="w-4 ml-2">
              <ArrowRight />
            </div>
          </div>
        )}
        {/* Final Submit */}
        {canContinue && isFinal && (
          <button className="flex items-center font-semibold" type="submit">
            Submit
            <div className="w-4 ml-2">
              <ArrowRight />
            </div>
          </button>
        )}
      </nav>
    </motion.div>
  );
};

export const QuizForm = ({ steps, subscriberEmail, successMessage, onClose }) => {
  const [step, setStep] = useState(0);
  const [values, setValues] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const activeStep = steps[step];

  const stepField = (activeStep && activeStep.field[0]) || {};

  const { fieldName, required } = stepField;

  const snakedName =
    (fieldName && fieldName.current && fieldName.current.replace(/-/g, '_')) || null;

  const [canContinue, setCanContinue] = useState(!required || (required && values[snakedName]));

  const isFinal = step + 1 === steps.length;

  useEffect(() => {
    setCanContinue(!required || (required && values[snakedName]));
  }, [values, fieldName, snakedName, required]);

  const nextStep = () => {
    if (step + 1 < steps.length) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleChange = (event) => {
    event.persist();
    setValues((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && canContinue && !isFinal) {
      event.preventDefault();
    }
  };

  const handleQuizSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Hit the serverless function
    await fetch('/.netlify/functions/submit-drip-quiz', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: subscriberEmail, values: { ...values } }),
    });

    setSuccess(true);
  };

  useEffect(() => {
    navigate(`${location.pathname}?form_step=${success ? 'completed' : step + 1}`);
    return () => {
      navigate(`${location.pathname}`);
    };
  }, [step, success]);

  return (
    <motion.form
      onSubmit={handleQuizSubmit}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="grid grid-cols-14 h-screen grid-rows-1"
      handleKeyDown={handleKeyDown}
    >
      <AnimatePresence exitBeforeEnter>
        {success ? (
          <motion.div
            key="successMessage"
            className="col-start-2 col-end-14 md:col-start-4 md:col-end-12 text-center self-center"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
          >
            <PortableTextBlock text={successMessage} />
            <div
              className="bg-brand rounded-full font-semibold text-lg py-1 px-12 shadow-lg text-white inline-block"
              onClick={onClose}
            >
              Close
            </div>
          </motion.div>
        ) : (
          <QuizStep
            activeStep={step}
            step={steps[step]}
            key={step}
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            isFinal={isFinal}
            canContinue={canContinue}
            snakedName={snakedName}
            handleKeyDown={handleKeyDown}
            loading={loading}
            nextText={!required && !values[snakedName] ? 'Skip' : 'Next'}
          />
        )}
      </AnimatePresence>
      <nav className="justify-between py-6 text-lg font-semibold col-start-2 col-end-14 hidden md:flex">
        {/* Back */}
        {step > 0 ? (
          <div className="flex items-center cursor-pointer" onClick={prevStep}>
            <div className="w-4 mr-2">
              <ArrowLeft />
            </div>
            Back
          </div>
        ) : (
          <div />
        )}
        {/* Next */}
        {canContinue && !isFinal && (
          <div className="flex items-center cursor-pointer" onClick={nextStep}>
            {!required && !values[snakedName] ? 'Skip' : 'Next'}
            <div className="w-4 ml-2">
              <ArrowRight />
            </div>
          </div>
        )}
        {/* Final Submit */}
        {canContinue && isFinal && (
          <button className="flex items-center font-semibold" type="submit">
            Submit
            <div className="w-4 ml-2">
              <ArrowRight />
            </div>
          </button>
        )}
      </nav>
    </motion.form>
  );
};
