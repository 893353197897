import React, { useState } from 'react';
import loadable from '@loadable/component';

// Libs
import AnchorLink from 'react-anchor-link-smooth-scroll';

import LogoIcon from '../../../svg/logo.svg';
import CloseIcon from '../../../svg/cross.svg';

import { StandardModal } from '../../modals/standardModal';

import { DripSubscribeForm } from '../../forms';

// Local Components
const ImageComponent = loadable(() => import('../../media/artImage'));

const VideoComponent = loadable(() => import('../../media/backgroundVideo'));

const FeatureBanner = ({
  heading,
  bannerText,
  layout,
  style,
  cta,
  backgroundColor,
  bannerVideo,
  bannerImage,
  logoLink,
  modalForm,
}) => {
  const cardClasses = `${
    layout === 'left' ? 'lg:col-start-2 lg:col-end-7' : 'lg:col-start-8 lg:col-end-14 ml-auto'
  } lg:self-center lg:rounded-lg lg:max-w-xl xl:py-32 shallow:py-20`;

  const fullClasess = `${
    layout === 'left'
      ? 'lg:col-start-1 lg:col-end-7 xl:pl-gutter'
      : 'lg:col-start-9 lg:col-end-15 xl:pr-gutter'
  } flex`;

  const [isOpen, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <section className="box-border grid lg:grid-cols-14 shallow:h-screen lg:h-banner">
        {/* Desktop Logo */}
        <div className="mb-6 hidden lg:grid lg:grid-cols-14 z-20 absolute py-10 shallow:hidden">
          <a
            href={logoLink}
            target="_blank"
            rel="noreferrer"
            className={`${style === 'card' ? 'col-start-2' : 'col-start-2'} w-56`}
          >
            <LogoIcon />
          </a>
        </div>
        {/* Banner Image */}
        <div className="h-bannerMobile relative lg:h-auto lg:col-start-1 lg:col-end-15 lg:row-end-1 lg:row-start-1">
          {bannerImage && !bannerVideo && (
            <ImageComponent
              {...bannerImage}
              loading="eager"
              className="object-cover absolute w-full h-full"
            />
          )}
          {bannerVideo && (
            <div className="object-cover absolute h-full w-full vimeo-background">
              <VideoComponent {...bannerVideo} />
            </div>
          )}
        </div>
        {/* Banner Text */}
        <div
          className={`bg-brand text-white px-gutter py-10 z-10 md:p-20 lg:row-end-1 lg:row-start-1 ${
            style === 'card' ? cardClasses : fullClasess
          }`}
          style={{ backgroundColor: backgroundColor && backgroundColor.hex }}
        >
          <div className="lg:self-end">
            <a
              href={logoLink}
              target="_blank"
              rel="noreferrer"
              className="block w-40 mb-6 lg:hidden shallow:block shallow:w-48"
            >
              <LogoIcon />
            </a>
            <h1 className="text-4xl font-display font-black pb-6 lg:text-5xl lg:leading-tight">
              {heading}
            </h1>
            <p className="text-lg">{bannerText}</p>

            {cta && cta.sectionID && cta.linkText && (
              <AnchorLink
                href={`#${cta.sectionID}`}
                className="bg-white text-brand rounded-full py-1 px-12 shadow-lg inline-block mt-6 font-semibold text-lg mr-3"
                style={{ color: backgroundColor && backgroundColor.hex }}
              >
                {cta.linkText}
              </AnchorLink>
            )}
            {modalForm && (
              <button
                className="bg-white text-brand rounded-full py-1 px-12 shadow-lg inline-block mt-6 font-semibold text-lg"
                onClick={() => setOpen(true)}
              >
                {modalForm.linkText}
              </button>
            )}
          </div>
        </div>
      </section>
      {modalForm && (
        <StandardModal isOpen={isOpen}>
          <div onClick={onClose} className="absolute right-0 top-0 p-6 cursor-pointer">
            <div className="w-6">
              <CloseIcon />
            </div>
          </div>
          <div className="prose-xl lg:prose-2xl max-w-none">
            <DripSubscribeForm {...modalForm._rawForm} onClose={onClose} />
          </div>
        </StandardModal>
      )}
    </>
  );
};

export default FeatureBanner;
