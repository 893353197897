import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';

import { PortableTextBlock } from '../../sanity';

import ArrowLeft from '../../../svg/leftArrow.svg';
import ArrowRight from '../../../svg/rightArrow.svg';

import { SvgIcon } from '../../media/svgIcon';

const Testimonial = ({ icon, _rawQuote, author, ...props }) => {
  return (
    <motion.div
      className="prose-xl col-start-2 col-end-14 md:col-start-4 md:col-end-12 text-center max-w-3xl  ml-auto mr-auto row-start-1"
      {...props}
    >
      <motion.div
        initial={{ y: 10 }}
        animate={{ y: 0 }}
        exit={{ y: 10 }}
        transition={{ ease: 'easeOut', duration: 0.3 }}
      >
        {icon && <SvgIcon icon={icon} className="w-16 mx-auto mb-3" />}
        <PortableTextBlock text={_rawQuote} />
      </motion.div>
      <motion.h5
        className="font-black"
        initial={{ y: 10 }}
        animate={{ y: 0 }}
        exit={{ y: 10 }}
        transition={{ ease: 'easeOut', duration: 0.3 }}
      >
        {author}
      </motion.h5>
    </motion.div>
  );
};

const Testimonials = ({ sectionSlug, backgroundColor, textColor, items }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const wrappedIndex = wrap(0, items.length, pageIndex);
  return (
    <section
      className="grid grid-cols-14 py-32 lg:py-40 bg-brand text-white"
      style={{
        backgroundColor: backgroundColor && backgroundColor.hex,
        color: textColor && textColor.hex,
      }}
    >
      <button
        className="col-start-13 w-6 self-center justify-self-center row-start-1 focus:outline-none hidden lg:block"
        onClick={() => setPageIndex(pageIndex - 1)}
      >
        <ArrowRight />
      </button>
      <button
        className="col-start-2 w-6 self-center justify-self-center row-start-1 focus:outline-none hidden lg:block"
        onClick={() => setPageIndex(pageIndex + 1)}
      >
        <ArrowLeft />
      </button>
      {items.length && (
        <AnimatePresence exitBeforeEnter>
          <Testimonial
            {...items[wrappedIndex]}
            key={pageIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            drag="x"
            dragMomentum={false}
            dragElastic={false}
            dragConstraints={{ left: 0, right: 0 }}
            onDragEnd={(e, { offset, velocity }) => {
              if (offset.x > 100) {
                setPageIndex(pageIndex - 1);
              } else if (offset.x < -100) {
                setPageIndex(pageIndex + 1);
              }
            }}
          />
        </AnimatePresence>
      )}
      {items.length > 1 && (
        <div className="col-start-2 col-end-14 flex justify-center mt-6 lg:hidden">
          {items.map((item, index) => (
            <button
              onClick={() => setPageIndex(index)}
              className={`text-hidden bg-current rounded-full h-2 w-2 mx-1 ${
                wrappedIndex === index ? 'opacity-100' : 'opacity-25'
              }`}
            >
              {index}
            </button>
          ))}
        </div>
      )}
    </section>
  );
};

export default Testimonials;
