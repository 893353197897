import React from 'react';

import { BlockWrapper } from '../blockWrapper';

import { PortableTextBlock } from '../../sanity';

const TextBlock = ({
  _rawText,
  text,
  centerText,
  wrapperWidth,
  align,
  backgroundColor,
  padding,
  blockDisplay,
  sectionSlug,
}) => {
  // Dynamic Tailwind Classes
  const wrapperClasses =
    wrapperWidth === 'narrow'
      ? align === 'center'
        ? 'md:col-start-4 md:col-end-12'
        : 'md:col-start-3 md:col-end-11'
      : 'md:col-start-3 md:col-end-13';

  const blockText = _rawText || text;

  return (
    <BlockWrapper
      className="grid-cols-14 grid text-goodFooter"
      style={{ backgroundColor: backgroundColor && backgroundColor.hex }}
      id={sectionSlug && sectionSlug.current}
      padding={padding}
      blockDisplay={blockDisplay}
    >
      <div
        className={`col-start-2 col-end-14 prose-xl lg:prose-2xl max-w-none ${
          centerText && 'text-center'
        } ${wrapperClasses}`}
      >
        <PortableTextBlock text={blockText} />
      </div>
    </BlockWrapper>
  );
};

export default TextBlock;
