import React from "react"

export const ButtonLink = ({ linkText, link }) => {
  return (
    <a
      href={link}
      className="bg-brand rounded-full font-semibold text-lg py-2 px-12 shadow-lg text-white"
      target="_blank"
      rel="noreferrer"
    >
      {linkText}
    </a>
  )
}
