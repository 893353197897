import React from 'react';

// Hooks
import { useTableCols } from '../../../hooks';

// SVG
import { SvgIcon } from '../../media/svgIcon';
import FeatureCheck from '../../../svg/featureCheck.svg';
import CheckMark from '../../../svg/check.svg';
import CrossIcon from '../../../svg/cross.svg';

// Components
import { BlockWrapper } from '../blockWrapper';

const TableHeader = ({ items }) => {
  const tableCols = useTableCols((items && items.length) || 0);
  return (
    <header className={`grid py-4 ${tableCols} gap-x-2 md:gap-x-0`}>
      <div />
      {items.map((item) => (
        <div className="flex justify-center self-center">
          {item.logo ? (
            <div className="w-32">
              <SvgIcon icon={item.logo} fillCurrent={false} />
            </div>
          ) : (
            <h5 className="font-semibold">{item.name}</h5>
          )}
        </div>
      ))}
    </header>
  );
};

const TableBody = ({ items, colCount, backgroundColor }) => {
  const tableCols = useTableCols(colCount);
  return (
    <div className="grid gap-y-6">
      <div className="border-t-2 border-goodFooter mt-2" />
      {items.map((item) => (
        <div className={`grid ${tableCols} gap-x-2 md:gap-x-0`}>
          <div
            className="flex sticky left-0 pr-2 lg:pr-0"
            style={{
              backgroundColor: (backgroundColor && backgroundColor.hex) || '#fff',
              background:
                (backgroundColor &&
                  backgroundColor.rgb &&
                  `linear-gradient(90deg, rgba(${backgroundColor.rgb.r},${backgroundColor.rgb.g},${backgroundColor.rgb.b},1) 80%, rgba(${backgroundColor.rgb.r},${backgroundColor.rgb.g},${backgroundColor.rgb.b},0) 100%)`) ||
                `linear-gradient(90deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%)`,
            }}
          >
            <h4 className="font-semibold text-l md:text-xl self-center">{item.feature}</h4>
          </div>
          {item.values.map((item) => (
            <div className="flex justify-center items-center py-4">
              <div className={`w-5 ${item === 'feature' ? 'md:w-8' : 'md:w-6'}`}>
                {item === 'feature' ? (
                  <FeatureCheck />
                ) : item === 'check' ? (
                  <CheckMark />
                ) : (
                  <CrossIcon />
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const ComparisonTable = ({
  subHeading,
  heading,
  padding,
  blockDisplay,
  backgroundColor,
  headerRows,
  tableRows,
}) => {
  return (
    <BlockWrapper
      className="md:px-gutter text-goodFooter"
      style={{ backgroundColor: backgroundColor && backgroundColor.hex }}
      blockDisplay={blockDisplay}
      padding={padding}
    >
      <div className="prose-xl lg:prose-2xl col-start-2 col-end-14 text-center md:px-20">
        <h4 className="text-brand font-black">{subHeading}</h4>
        <h3 className="font-display">{heading}</h3>
      </div>
      {/* Desktop Table */}
      <div className="my-20 max-w-5xl md:mx-auto overflow-x-auto ml-gutter md:px-0 md:overflow-x-auto pb-5 lg:pb-0">
        <TableHeader items={headerRows} />
        <TableBody
          items={tableRows}
          colCount={(headerRows && headerRows.length) || 0}
          backgroundColor={backgroundColor}
        />
      </div>
    </BlockWrapper>
  );
};

export default ComparisonTable;
