import React from 'react';

// Hooks
import { BlockWrapper } from '../blockWrapper';

import { ArtImage } from '../../media';

const PageTransition = ({ image, sectionSlug, padding, blockDisplay }) => {
  return (
    <BlockWrapper
      id={sectionSlug && sectionSlug.current}
      padding={padding}
      blockDisplay={blockDisplay}
    >
      {image && <ArtImage {...image} />}
    </BlockWrapper>
  );
};

export default PageTransition;
