import React from 'react';
import PropTypes from 'prop-types';

import {
  FeatureBanner,
  TextBlock,
  PageTransition,
  TrapperStats,
  CardGrid,
  FeatureSection,
  Faqs,
  Testimonials,
  ComparisonTable,
  PersistentOffer,
  PersistentModalForm,
  PageNav,
} from './blocks';

const BlockZone = ({ blocks }) => {
  // Map blocks to return correct type
  const block = blocks.map((block) => {
    switch (block._type) {
      case 'featureBanner':
        return <FeatureBanner {...block} />;
      case 'textBlock':
        return <TextBlock {...block} />;
      case 'pageTransition':
        return <PageTransition {...block} />;
      case 'trapperStats':
        return <TrapperStats {...block} />;
      case 'cardGrid':
        return <CardGrid {...block} />;
      case 'featureSection':
        return <FeatureSection {...block} />;
      case 'faqs':
        return <Faqs {...block} />;
      case 'testimonials':
        return <Testimonials {...block} />;
      case 'comparisonTable':
        return <ComparisonTable {...block} />;
      case 'persistentOffer':
        return <PersistentOffer {...block} />;
      case 'persistentModalForm':
        return <PersistentModalForm {...block} />;
      case 'pageNav':
        return <PageNav {...block} />;
      default:
        return null;
    }
  });
  return <>{block}</>;
};

BlockZone.propTypes = {
  blocks: PropTypes.array.isRequired,
};

export default BlockZone;
