import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"

export const ButtonAnchor = ({ linkText, sectionID }) => {
  return (
    <AnchorLink
      href={`#${sectionID}`}
      className="bg-brand rounded-full font-semibold text-lg py-2 px-12 shadow-lg text-white"
    >
      {linkText}
    </AnchorLink>
  )
}
