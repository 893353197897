import React from 'react';

import { BlockWrapper } from '../blockWrapper';

import { TrapperCarousel } from '../../carousels/trapperCarousel';

const TrapperStat = ({ title, end, showLive, preffix, suffix }) => {
  return (
    <div className="text-center ">
      <h3 className="text-brand font-display text-5xl">
        {preffix}
        {end}
        {suffix}
      </h3>
      <h4 className="text-l font-semibold">{title}</h4>
      {showLive && (
        <span className="bg-tintorange text-brand font-bold rounded-full py-0 px-6 mt-4 inline-block text-sm relative">
          <span className="animate-pulse">Live</span>
        </span>
      )}
    </div>
  );
};

const TrapperStats = ({
  heading,
  text,
  sectionSlug,
  padding,
  blockDisplay,
  backgroundColor,
  items,
}) => {
  return (
    <BlockWrapper
      id={sectionSlug && sectionSlug.current}
      className="grid grid-cols-14 overflow-hidden text-goodFooter"
      style={{ backgroundColor: backgroundColor && backgroundColor.hex }}
      padding={padding}
      blockDisplay={blockDisplay}
    >
      {/* Text */}
      <div className="prose-xl lg:prose-2xl text-center col-start-2 col-end-14 max-w-3xl ml-auto mr-auto">
        <h2 className="font-display">{heading}</h2>
        <p>{text}</p>
      </div>
      {/* Mobile Stats */}
      <div className="col-start-2 col-end-14 mt-10 md:hidden">
        <TrapperCarousel items={items} />
      </div>
      {/* Desktop Stats */}
      <div
        className={`bg-white col-start-2 col-end-14 lg:col-start-3 lg:col-end-13 xl:col-start-4 xl:col-end-12 rounded-xl shadow-xl mt-20 ${
          items.length === 2 ? 'grid-cols-2' : 'grid-cols-3'
        } py-6 px-5 divide-x-2 divide-gray-200 hidden md:grid`}
      >
        {items.map((item, index) => (
          <TrapperStat {...item} index={index} />
        ))}
      </div>
    </BlockWrapper>
  );
};

export default TrapperStats;
