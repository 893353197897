import React from "react"

import CheckMark from "../../svg/check.svg"

export const CheckList = ({ items }) => {
  return (
    items.length && (
      <div>
        {items.map(item => (
          <div className="font-semibold flex items-center my-4">
            <span className="w-4 block mr-4 lg:w-6">
              <CheckMark />
            </span>
            {item}
          </div>
        ))}
      </div>
    )
  )
}
