import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// hooks
import { useMediaQuery } from '@react-hook/media-query';
import { useOfferLayout } from '../../../hooks';

import { PortableTextBlock } from '../../sanity/portableTextBlock';

const PersistentOffer = ({
  badgeText,
  _rawText,
  text,
  link,
  linkType = 'link',
  sectionID,
  layout,
  blockDisplay,
}) => {
  const layoutClass = useOfferLayout(layout || 'center');
  const matches = useMediaQuery('only screen and (min-width: 768px)');
  const { hideMobile, hideDesktop } = blockDisplay || {};
  const showBlock = matches ? !hideDesktop : !hideMobile;
  const blockText = _rawText || text;
  return (
    showBlock && (
      <div
        className={`fixed bottom-0 px-gutter pb-4 flex justify-center z-50 w-full lg:pb-6 ${layoutClass}`}
      >
        {linkType === 'link' ? (
          <a
            href={link}
            className="bg-white rounded-full shadow-xl p-1 flex items-center border-gray-200 border text-xs md:text-sm"
            target="_blank"
            rel="noreferrer"
          >
            {badgeText && badgeText !== '' && (
              <span className="bg-brand rounded-full text-white font-black py-px px-3">
                {badgeText}
              </span>
            )}
            <div className="px-3 text-brand font-black">
              <PortableTextBlock text={_rawText} />
            </div>
          </a>
        ) : (
          linkType === 'anchor' && (
            <AnchorLink
              href={`#${sectionID}`}
              className="bg-white rounded-full shadow-xl p-1 flex items-center border-gray-200 border text-xs md:text-sm"
              target="_blank"
              rel="noreferrer"
            >
              {badgeText && badgeText !== '' && (
                <span className="bg-brand rounded-full text-white font-black py-px px-3">
                  {badgeText}
                </span>
              )}
              <div className="px-3 text-brand font-black">
                <PortableTextBlock text={blockText} />
              </div>
            </AnchorLink>
          )
        )}
      </div>
    )
  );
};

export default PersistentOffer;
